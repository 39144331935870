import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { useMyOrgId } from '../components/Organization';
import { type NotificationType } from '../types';
import { useAnalytics } from './AnalyticsContext';

class NotificationsAnalytics {
  constructor(
    private readonly analytics: AnalyticsBrowser,
    readonly orgId: string | null
  ) {}

  trackGameNotificationViewed(props: { notificationType: NotificationType }) {
    this.analytics.track(
      'Game Notification Viewed',
      this.buildEventProps(props)
    );
  }

  private buildEventProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useNotificationsAnalytics(): NotificationsAnalytics {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();

  return useMemo(
    () => new NotificationsAnalytics(analytics, orgId),
    [analytics, orgId]
  );
}
