import pluralize from 'pluralize';
import { useEffectOnce } from 'react-use';

import { useNotificationsAnalytics } from '../../../analytics/notifications';
import {
  type GuestCouldNotJoinNotification,
  NotificationType,
} from '../../../types';
import { useTriggerBookNowWithGameSessionGamePack } from '../../Product/BookNow';
import { useNotificationDataSource } from '../Context';
import { type BaseNotificationProps } from '../type';

function GuestCouldNotJoinNotificationView(
  props: BaseNotificationProps & {
    notification: GuestCouldNotJoinNotification;
  }
): JSX.Element {
  const capacity = props.notification.metadata.seatCap;
  const notificationDataSource = useNotificationDataSource();
  const triggerBookNow = useTriggerBookNowWithGameSessionGamePack();
  const analytics = useNotificationsAnalytics();

  useEffectOnce(() => {
    analytics.trackGameNotificationViewed({
      notificationType: NotificationType.GuestCouldNotJoin,
    });
  });

  const handleUpgrade = (event: React.MouseEvent) => {
    event.stopPropagation();
    triggerBookNow('guest-could-not-join-notif');
    notificationDataSource.dismiss(props.notification.id);
  };

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    notificationDataSource.dismiss(props.notification.id);
  };

  return (
    <div
      className={`
        py-5 px-5 w-166
        flex items-center justify-between
        text-main-layer rounded-xl
        bg-gradient-to-tr from-yellow-start to-yellow-end
      `}
    >
      <div className='flex-1'>
        <p className='font-bold'>A guest was not able to join your venue.</p>
        <p className='text-sms'>
          Your venue is capped to {capacity} {pluralize('player', capacity)}.
          Upgrade now to let more guests in.
        </p>
      </div>

      <div className='flex-none flex flex-col gap-2.5 justify-between items-center'>
        <button
          type='button'
          className='w-32 h-10 btn-secondary'
          onClick={handleUpgrade}
        >
          Upgrade
        </button>
        <button
          type='button'
          className='text-2xs font-bold hover:underline'
          onClick={handleClose}
        >
          No Thanks
        </button>
      </div>
    </div>
  );
}

// Required for lazy import
// eslint-disable-next-line import/no-default-export
export default GuestCouldNotJoinNotificationView;
